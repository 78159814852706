import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/poc/banner.jpg";
import ContrastSection from "../../../components/ContrastSection";

import { Container } from "reactstrap";
import SvgIconsSet from "../../../components/SvgIconsSet";
import Img from "gatsby-image";

//Accordian
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import plus from "../../../images/icons/plus.svg";
import minus from "../../../images/icons/minus.svg";

//Images
import BannerConcept from "../../../images/services/poc/banner-concept.jpg";
import BannerDesign from "../../../images/services/poc/banner-webdesign.jpg";
import BannerLongterm from "../../../images/services/poc/banner-longterm.jpg";
import ConsultationBanner from "../../../images/consultation.jpg";

//Process
import OurProcess from "../../../components/services/OurProcess";

//Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import BookConsultation from "../../../components/BookConsultation";

const PocMvp = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        keyword="MVP"
        title="Prototype and Design from POC to MVP"
        description="We work with you to create a prototype, proof of concept or minimum viable product so you can roadtest feasibility early. Contact us today to find out more."
      />
      {/* <div className="intro intro-banner">
        <div className="intro-banner-img" id="up">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Prototype, PoC &amp; MVP Services</h1>
              <hr />
            </div>
          </div>
        </div>
      </div> */}
      <IntroBanner
        title="Prototype &amp; Design from POC to MVP"
        subtitle="We take you from ideation to delivery and beyond"
        image={Banner}
      />
      {/* <ContrastSection
        title="We take you from ideation to delivery and beyond"
        para={["Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar."]}
      /> */}
      <div className="container pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerConcept}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">
              Concept Refinement &amp; Technical Scoping
            </h2>
            <p>
              <strong>
                Test the technical feasibility of your idea right from the
                start
              </strong>
              <br />
              We'll work with you to turn your idea into a viable product, and
              scope the steps needed to take you through development and
              delivery.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerDesign}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">
              Web Design &amp; Content Management Services
            </h2>
            <p>
              <strong>
                A well-structured website is the cornerstone of success{" "}
              </strong>
              <br />
              We'll work with you to create a high-quality website that matches
              your needs, ensures ease of maintenance and maximises cost
              efficiency.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerLongterm}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Long-term Support</h2>
            <p>
              <strong>A great product is always improving</strong>
              <br />
              That's why we offer ongoing support to ensure you can continue to
              build your user base and have a clear roadmap for your next
              product iteration.
            </p>
          </div>
        </div>
        <div className="getting-touch">
          <GetInTouchbtn textdce={"Free consultation"} />
        </div>
      </div>
      <Container>
        <OurProcess />
      </Container>
      
      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={ConsultationBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "productinnovation-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default PocMvp;
